import React, { memo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Link from 'next/link'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import YoutubeIcon from 'components/icons/youtube-icon.svg'
import FacebookIcon from 'components/icons/facebook.svg'
import InstagramIcon from 'components/icons/instagram.svg'
import LinkedinIcon from 'components/icons/linkedin.svg'
import TiktokIcon from 'components/icons/tiktok-icon.svg'
import PhoneIcon from 'components/icons/phone-icon.svg'
import WhatsappIcon from 'components/icons/whatsapp-light.svg'
import usePageProps from 'hooks/usePageProps'
import Page from 'components/layout/Page'
import Check from 'public/img/vdp/check_validation.svg'
import useMediaQuery from '@mui/material/useMediaQuery'
import sx from './styles'

const NotFound = () => {
  const {
    socialLinks, whatsapp, whatsappVTA, whatsappWelcomeMessage, homePhoneNumber,
  } = usePageProps()
  const { formatMessage: f } = useIntl()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const features = [
    {
      title: <FormattedMessage id="BUY_A_CAR" />,
      description: <FormattedMessage id="NOT_FOUND_DESCRIPTION_1" />,
      imageUrl: '/img/Illus_CompraTuAuto.svg',
      button: {
        text: <FormattedMessage id="SEE_ALL_VEHICLES" />,
        href: f({ id: 'ROUTE_SEARCH' }, { query: null }),
      },
    },
    {
      title: <FormattedMessage id="NOT_FOUND_TITLE_2" />,
      description: <FormattedMessage id="NOT_FOUND_DESCRIPTION_2" />,
      imageUrl: '/img/Illus_VendeOCambia.svg',
      button: {
        text: <FormattedMessage id="SELL_TODAY" />,
        href: f({ id: 'ROUTE_SELL_YOUR_CAR' }),
      },
    },
  ]

  const feature = (item) => (
    <>
      <Box
        sx={sx.feature}
      >
        <img
          src={item.imageUrl}
          alt={item.title}
          width="146"
        />
      </Box>
      <Box sx={sx.featureContent}>
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          sx={sx.featureTitle}
        >
          {item.title}
        </Typography>

        <Typography
          variant="body2"
          component="div"
          gutterBottom
          sx={sx.featureText}
        >
          {item.description}
        </Typography>
        <Link
          fullWidth
          href={item.button.href}
          passHref
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            fullWidth
          >
            {item.button.text}
          </Button>
        </Link>
      </Box>
    </>
  )

  const socialNetworks = (
    <Grid
      container
      spacing={2}
      sx={sx.socialNetworks}
    >
      <Grid item>
        <a
          href={socialLinks?.facebook}
          target="_blank"
          rel="noreferrer"
        >
          <FacebookIcon />
        </a>
      </Grid>
      <Grid item>
        <a
          href={socialLinks?.instagram}
          target="_blank"
          rel="noreferrer"
        >
          <InstagramIcon />
        </a>
      </Grid>
      <Grid item>
        <a
          href={socialLinks?.linkedin}
          target="_blank"
          rel="noreferrer"
        >
          <LinkedinIcon />
        </a>
      </Grid>
      <Grid item>
        <a
          href={socialLinks?.tiktok}
          target="_blank"
          rel="noreferrer"
        >
          <TiktokIcon />
        </a>
      </Grid>
      <Grid item>
        <a
          href={socialLinks?.youtube}
          target="_blank"
          rel="noreferrer"
        >
          <YoutubeIcon />
        </a>
      </Grid>
    </Grid>
  )

  const phones = (
    <Grid
      container
      gap={2}
      mt={4}
      justifyContent="center"
      textAlign="center"
    >
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="center"
        textAlign="center"
        alignItems="center"
      >
        <Grid
          item
          display="flex"
        >
          <Box
            pt={0.5}
            mr={1}
          >
            <PhoneIcon />
          </Box>
          <Typography
            variant="h6"
            align="center"
          >
            <FormattedMessage id="NOT_FOUND_SCAM_TEL" />
          </Typography>
        </Grid>
        <Grid
          item
          textAlign="left"
          ml={1}
        >
          <a
            href={`tel:${homePhoneNumber}`}
            rel="noopener noreferrer"
            style={{
              display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black',
            }}
          >
            <Typography
              variant="body"
            >
              {homePhoneNumber}
            </Typography>
          </a>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        justifyContent="center"
        textAlign="center"
        alignItems="center"
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        gap={1}
      >
        <Grid
          item
          display="flex"
        >
          <Box
            pt={0.5}
            mr={1}
          >
            <WhatsappIcon />
          </Box>
          <Typography
            variant="h6"
            align="center"
            mr={1}
          >
            <FormattedMessage id="NOT_FOUND_SCAM_WHATSAPP" />
          </Typography>
        </Grid>

        <Grid
          item
          style={{ width: 'fit-content', marginRight: '10px' }}
        >
          <a
            href={`//wa.me/${whatsapp}?text=${whatsappWelcomeMessage}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black',
            }}
          >
            <Typography
              variant="body"
              align="center"
            >
              <FormattedMessage id="NOT_FOUND_SCAM_COMPRAS" />
            </Typography>

            <Typography
              variant="body"
              align="center"
              ml={1}
            >
              <FormattedMessage id={`${whatsapp}`} />
            </Typography>
          </a>
        </Grid>

        {
          !isMobile && (
            <Typography
              variant="body"
              align="center"
              mr={1}
            >
              <FormattedMessage id=" | " />
            </Typography>
          )
        }

        <Grid
          item
          style={{ width: 'fit-content' }}
        >
          <a
            href={`//wa.me/${whatsappVTA}?text=${whatsappWelcomeMessage}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black',
            }}
          >
            <Typography
              variant="body"
              align="center"
            >
              <FormattedMessage id="NOT_FOUND_SCAM_VENTAS" />
            </Typography>

            <Typography
              variant="body"
              align="center"
              ml={1}
            >
              <FormattedMessage id={`${whatsappVTA}`} />
            </Typography>
          </a>
        </Grid>
      </Grid>
    </Grid>
  )

  const bottomSection = (
    <Box sx={sx.bottomSection}>
      <Container
        sx={sx.bannerInner}
        component={Box}
        alignItems="center"
      >
        <Grid
          container
          spacing={2}
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            md={9}
            lg={9}
            display="flex"
          >
            <Box
              mr={1}
              pt={0.5}
            >
              <Check />
            </Box>
            <Typography
              variant="h5"
              align="left"
              sx={sx.bottomSectionText}
            >
              <FormattedMessage id="NOT_FOUND_SCAM_SOCIAL_TITLE" />
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            lg={3}
          >
            {socialNetworks}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          lg={8}
          py={2}
        >
          <Typography
            variant="body1"
            align="left"
            sx={sx.bottomSectionText}
          >
            <FormattedMessage id="NOT_FOUND_SCAM_DISCLAIMER" />
          </Typography>
        </Grid>
      </Container>
    </Box>
  )

  return (
    <Page>
      <Container
        maxWidth="lg"
        sx={sx.root}
      >
        <Typography
          variant="h1"
          align="center"
          sx={sx.title}
        >
          <FormattedMessage id="NOT_FOUND_TITLE" />
        </Typography>

        <Typography
          variant="h3"
          align="center"
          sx={sx.scamTitle}
        >
          <FormattedMessage id="NOT_FOUND_SCAM_TITLE" />
        </Typography>
        <Typography
          variant="body1"
          align="center"
          sx={sx.scamText}
        >
          <FormattedMessage id="NOT_FOUND_SCAM_TEXT" />
        </Typography>

        <Typography
          variant="h3"
          align="center"
          sx={sx.scamText}
        >
          <FormattedMessage id="NOT_FOUND_SCAM_SUBTITLE" />
        </Typography>
        <Typography
          variant="body1"
          align="center"
          sx={sx.scamText}
        >
          <FormattedMessage id="NOT_FOUND_SCAM_TEXT2" />
        </Typography>

        {phones}

        <Grid
          container
          mb={2}
          justifyContent="space-evenly"
          alignItems="end"
          flexDirection="row"
        >
          {features.map((item, i) => (
            <Grid
              item
              xs={12}
              md={4}
              key={`feature-item-idx-${i}`}
            >
              {feature(item, i)}
            </Grid>
          ))}
        </Grid>

        {bottomSection}
      </Container>

    </Page>
  )
}
export default memo(NotFound)
