const styles = {

  root: {
    padding: { xs: 2, lg: (4, 11) },
  },

  title: {
    color: 'secondary.main',
    fontWeight: 700,
    pt: { xs: 8, lg: 0 },
  },

  scamTitle: {
    mt: 3,
  },

  scamText: {
    m: '0 auto',
    mt: 2,
    maxWidth: '600px',
  },

  notFoundInner: {
    paddingY: 4,
    justifyContent: 'center',
  },

  mainBanner: {
    textAlign: 'center',
    maxWidth: '100%!important',
    '& img': {
      maxWidth: { xs: '60%!important', md: 'unset' }
      ,
    },
  },

  feature: {
    marginTop: 6,
  },

  featureContent: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 2,
    margin: ' 0 auto',
    whiteSpace: { xs: 'unset', md: 'pre-line' },
    width: { md: 'fit-content' },
  },

  featureIcon: {
    marginBottom: 2,
  },

  featureTitle: {
    marginBottom: 2,
  },

  featureImage: {
    padding: 2,
    '& img': {
      maxWidth: '146px',
    },
    marginTop: { xs: 4, md: 'unset' },
  },

  featureText: {
    whiteSpace: 'pre-wrap',
    marginBottom: 3,
  },

  featureGridItem: {
    paddingY: `${3}px!important`,
    boxShadow: { xs: '0px 30px 3px -30px divider', md: 'unset' },

  },

  link: {
    textDecoration: 'none',
    color: '#666666',
    textAlign: 'center',
  },

  bottomSection: {
    background: 'background.tile',
  },

  bannerInner: {
    textAlign: { xs: 'center', lg: 'left' },
    padding: { xs: 2, lg: (4, 11) },
  },

  socialNetworks: {
    justifyContent: 'space-evenly',
  },
}

export default styles
