import NotFoundApp from 'apps/NotFound'
import { getCommonStaticProps } from 'commonPage'
import commonMessages from 'lang/es/common'
import notFoundMessages from 'lang/es/404'

export default NotFoundApp

export const getStaticProps = async () => {
  const commonStaticProps = await getCommonStaticProps()

  return {
    props: {
      messages: {
        ...commonMessages,
        ...notFoundMessages,
      },
      ...commonStaticProps,
    },
  }
}
